<template>
  <div>
    <b-table-custom
        :table-data="dataObjectList"
        :table-columns="tableColumns"
        :is-data-loading="isDataLoading"
        @menuClick="menuClick"
    >
      <template #menu>
        <b-dropdown-item v-for="(item, index) in menuItemList" :key="index" @click="openModal(item['name'])">
          <font-awesome-icon :icon="item['icon']" :class="`text-${item['color']}`"/>
          <span>{{ item['label'] }}</span>
        </b-dropdown-item>
      </template>
    </b-table-custom>

    <custom-modal
        ref="action-menu-modal"
        id="action-menu-modal"
        :title="currentModal['title']"
        :ok-title="currentModal['okButton']"
        :cancel-title="currentModal['cancelButton']"
        :variant="currentModal['variant']"
        @ok="currentModal['okFunction']"
        :is-manual-close="true"
    >
      <b-card-text v-if="modalName === 'modal-set-user-password'">
        <custom-input
            label="Username"
            :value="dataObjectSelectedClone['user_name']"
            :disabled="true"
        />
        <custom-input
            label="Password"
            v-model="dataObjectSelectedClone['password']"
        />
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-create-invoice-topup'">
        <custom-input
            label="Username"
            :value="dataObjectSelectedClone['user_name']"
            :disabled="true"
        />
        <money-box
            label="Topup amount"
            v-model="dataObjectSelectedClone['topup_amount']"
        />
      </b-card-text>
      <b-card-text v-else-if="modalName === 'modal-update-user'">
        <custom-input
            label="Username"
            v-model="dataObjectSelectedClone['user_name']"
            disabled
        />
        <money-box
            label="Current balance"
            v-model="dataObjectSelectedClone['balance']"
        />
        <b-row>
          <b-col cols="12" md="6">
            <custom-input
                label="Facebook name"
                v-model="dataObjectSelectedClone['facebook_name']"/>
          </b-col>
          <b-col cols="12" md="6">
            <custom-input
                label="Facebook URL"
                v-model="dataObjectSelectedClone['facebook_url']"/>
          </b-col>
        </b-row>
        <custom-input
            label="Note"
            v-model="dataObjectSelectedClone['note']"/>
      </b-card-text>
    </custom-modal>
  </div>
</template>

<script>

import bTableCustom from '@/views/components/table/BTableCustom.vue'
import MoneyBox from "@/views/components/MoneyBox";
import CustomInput from "@/views/components/form/CustomInput.vue";

import inputHelper from '@/mixins/utils/inputHelper'
import dataRequester from '@/mixins/utils/dataRequester'
import dataObjectList from "@/mixins/utils/dataObjectList";
import md5 from "md5";
import CustomModal from "@/views/components/CustomModal.vue";
import invoiceService from "@/mixins/utils/axios/invoice.service";
import userService from "@/mixins/utils/axios/user.service";

export default {
  name: "UserSetting",
  components: {
    CustomModal,
    bTableCustom,
    MoneyBox,
    CustomInput
  },
  mixins: [inputHelper, dataRequester, dataObjectList],
  computed: {
    currentModal () {
      switch (this.modalName) {
        case 'modal-update-user':
          return {
            title: 'Cập nhật thông tin người dùng',
            okTitle: 'Cập nhật',
            variant: 'info',
            okFunction: this.updateUserInfo
          }
        case 'modal-set-user-password':
          return {
            title: 'Cài đặt mật khẩu',
            okTitle: 'Cài đặt mật khẩu',
            variant: 'danger',
            okFunction: this.setPassword
          }
        case 'modal-create-invoice-topup':
          return {
            title: 'Tạo hóa đơn nạp tiền',
            okTitle: 'Tạo hóa đơn',
            variant: 'success',
            okFunction: this.createInvoiceTopup
          }
        default:
          return {
            title: '',
            variant: 'primary',
            okButton: 'OK',
            okFunction: function () {
              console.log('default')
            }
          }
      }
    }
  },
  data () {
    let self = this
    return {
      tableColumns: [
        {
          key: 'user_name'
        },
        {
          key: 'full_name'
        },
        {
          key: 'facebook_name',
          formatter: (value, index, item) => {
            return `<a class="font-weight-bold" href="${item['facebook_url']}" target="_blank">${value}</a>`
          }
        },
        {
          key: 'email'
        },
        {
          key: 'phone_number'
        },
        {
          key: 'balance',
          formatter: (value) => {
            if (value < 0) {
              return `<strong class="text-danger">${ self.currencyFormat(value) }</strong>`
            } else if (value === 0) {
              return `${ self.currencyFormat(value) }`
            } else if (value > 0) {
              return `<strong class="text-success">${ self.currencyFormat(value) }</strong>`
            }
          }
        },
        {
          key: 'cloud_service_count',
          formatter: (value) => {
            if (value > 0) {
              return `<strong class="text-success">${ value }</strong>`
            } else {
              return value
            }
          }
        },
        {
          key: 'note'
        },
        {
          key: 'is_active'
        },
        {
          key: 'menu'
        }
      ],
      isDataLoading: false,
      menuItemList: [
        {
          name: 'modal-update-user',
          label: 'Cập nhật',
          icon: 'fa-solid fa-edit',
          color: 'info',
        },
        {
          name: 'modal-set-user-password',
          label: 'Cài Đặt Mật Khẩu',
          icon: 'fa-solid fa-key',
          color: 'danger',
        },
        {
          name: 'modal-create-invoice-topup',
          label: 'Nạp tiền',
          icon: 'fa-solid fa-dollar-sign',
          color: 'success',
        }
      ],
      modalName: null
    }
  },
  created () {
    let self = this
    self.isDataLoading = true
    this.runApi(userService.listUsersWithCloudService(), function (response) {
      self.dataObjectList = response.data.sort((a, b) => b['created_at'].localeCompare(a['created_at']))
      self.isDataLoading = false
    })
  },
  methods: {
    menuClick (rowData) {
      this.dataObjectSelected = rowData
      this.dataObjectSelectedClone = {...rowData}
    },
    openModal(modalName) {
      this.modalName = modalName

      this.$refs['action-menu-modal'].show()
    },
    closeModal() {
      this.$refs['action-menu-modal'].hide()
    },

    setPassword () {
      const self = this
      this.updateRowData(function () {
        self.runApi(userService.setPassword({
          user_uuid: self.dataObjectSelected['uuid'],
          password: md5(self.dataObjectSelectedClone['password'])
        }), null, null, function () {
          self.dataObjectSelectedClone['password'] = ''
          self.closeModal()
        }, true)
      })
    },
    updateUserInfo() {
      const self = this
      this.updateRowData(function (rowData) {
        self.runApi(userService.adminUpdateUserInfo({
          user_uuid: self.dataObjectSelected['uuid'],
          balance: self.dataObjectSelectedClone['balance'],
          facebook_name: self.dataObjectSelectedClone['facebook_name'],
          facebook_url: self.dataObjectSelectedClone['facebook_url'],
          note: self.dataObjectSelectedClone['note']
        }), function () {
          self.setItemProperty(rowData, 'balance', self.dataObjectSelectedClone['balance'])
          self.setItemProperty(rowData, 'facebook_name', self.dataObjectSelectedClone['facebook_name'])
          self.setItemProperty(rowData, 'facebook_url', self.dataObjectSelectedClone['facebook_url'])
          self.setItemProperty(rowData, 'note', self.dataObjectSelectedClone['note'])
        }, null, function () {
          self.closeModal()
        }, true)
      })
    },
    createInvoiceTopup() {
      let self = this
      this.runApi(invoiceService.hardCreateInvoice({
        type: 'topup',
        user_uuid: self.dataObjectSelected['uuid'],
        topup_amount: self.dataObjectSelectedClone['topup_amount']
      }), function () {
        self.dataObjectSelected['balance'] += self.dataObjectSelectedClone['topup_amount']
      }, undefined, function () {
        self.closeModal()
      }, true)
    }
  }
}
</script>

<style scoped>

</style>
